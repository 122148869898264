@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Nunito";
  src: url("../public/fonts/Nunito-Regular.ttf");
}
@font-face {
  font-family: "Titillium";
  src: url("../public/fonts/TitilliumWeb-Regular.ttf");
}
@font-face {
  font-family: "Playfair";
  src: url("../public/fonts/PlayfairDisplay-Regular.ttf");
}
@font-face {
  font-family: "Oswald";
  src: url("../public/fonts/Oswald-Regular.ttf");
}
@font-face {
  font-family: "Lato";
  src: url("../public/fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: "Merriweather";
  src: url("../public/fonts/Merriweather-Regular.ttf");
}
:root {
  --secondary-color: #481e5f;
  --secondary-color: #3d3b38;
  --color-title-grey: grey;
  --oniria-gold: #dda448;
  --button-hover: #a7782c;
  --button-cancel-hover: #ccd4d3;
  --white: #ffffff;
  --oniria-sky: #ecd7cc;
  --oniria-red: #a61d1d;
  --oniria-red-hover: #a61d1d2e;
  --font-family: "Montserrat";
  --grey-cancelled: #e4e4e4;
  --grey-dark: rgb(48, 55, 65);
  --grey-blue: #515d6e;
  --oniria-gold-hover: #dda4482e;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat" !important;
}
html {
  min-height: 100%;
  position: relative;
  font-size: 90%; /* Esto hace que 1rem = 10px, en lugar de los 16px predeterminados */
}
body {
  color: var(--white);
  background-color: var(--secondary-color);
}

/* CUSTOM ONIRIA BUTTON */
.oniria-btn {
  background-color: var(--oniria-gold) !important;
}

.oniria-btn:hover {
  background-color: var(--button-hover) !important;
}

.oniria-btn-cancel {
  background-color: var(--white) !important;
  color: var(--oniria-gold) !important;
}

.oniria-btn-cancel:hover {
  background-color: var(--button-cancel-hover) !important;
  color: var(--oniria-gold) !important;
}

.oniria-btn-qrs {
  background-color: var(--secondary-color) !important;
}
.oniria-btn-qrs:hover {
  background-color: var(--white) !important;
  color: var(--secondary-color) !important;
}
.oniria-btn-check {
  background-color: var(--white) !important;
  border: 2px solid var(--secondary-color) !important;
  color: var(--secondary-color) !important;
}
.oniria-btn-check:hover {
  background-color: var(--secondary-color) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
}

.oniria-btn-check-validated {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--secondary-color) !important;
  border-color: var(--white) !important;
  color: var(--white) !important;
}

.oniria-btn-delete {
  background-color: var(--white) !important;
  border: 1px solid var(--oniria-red) !important;
  color: var(--oniria-red) !important;
}
.oniria-btn-delete:hover {
  background-color: var(--oniria-red-hover) !important;
}

.oniria-btn-icon-gold {
  border: 2px solid var(--oniria-gold) !important;
  color: var(--oniria-gold) !important;
}
.oniria-btn-icon-gold:hover {
  background-color: var(--oniria-gold) !important;
  color: var(--white) !important;
  border-color: var(--white) !important;
}
/* END CUSTOM ONIRIA BUTTON */

.oniria-colorText {
  color: var(--secondary-color) !important;
  font-weight: "bolder" !important;
}

.oniria-title {
  display: flex;
  color: var(--color-title-grey);
  font-weight: bold;
  justify-content: space-evenly;
  align-items: center;
}

.oniria-footer {
  width: 100%;
  background-color: "var(--oniria-sky)" !important;
  position: absolute;
  bottom: 0;
  z-index: 10000;
}

.MuiDrawer-paper {
  background-color: var(--secondary-color) !important;
}

.uploadedImage {
  width: 120px;
  height: 100px;
}
/* CUSTOM GROUP BUTTON POPOVER */
.popover-btn {
  background-color: var(--white) !important;
  color: #000000 !important;
  font-size: 12px !important;
  justify-content: flex-start !important;
  padding-right: 20px !important;
  min-width: 180px !important;
}

.popover-btn:hover {
  background-color: var(--oniria-gold) !important;
  color: var(--white) !important;
}
.popover-btn:not(:last-of-type) {
  border-color: #bdbdbd !important;
}
/*END CUSTOM GROUP BUTTON POPOVER */
.error-checkbox {
  color: red;
}
/* CSV LINK */
.csv-link {
  color: #000000;
  text-decoration: none;
  width: 100%;
  height: 100%;
  text-align: start;
}
.popover-btn:hover .csv-link {
  color: #ffffff;
}
/*border row table none*/
.rowBorder {
  border: "none" !important;
}

.radio-oniria {
  color: var(--secondary-color) !important;
  &.Mui-checked {
    color: var(--secondary-color);
  }
}

.checkbox-oniria {
  color: var(--secondary-color) !important;
  &.Mui-checked {
    color: var(--secondary-color);
  }
}

.oniria-icon-btn {
  color: var(--oniria-gold) !important;
}
.oniria-icon-btn-delete:hover {
  color: var(--oniria-red) !important;
}

.oniria-border-btn {
  border: 1px solid var(--oniria-gold) !important;
  color: var(--oniria-gold) !important;
}
.oniria-border-btn:hover {
  background-color: var(--oniria-gold-hover) !important;
}

.form-input-error {
  color: var(--oniria-red) !important;
}
